.App {
  padding: 50px 100px;
  background-color: #439d49;
  min-height: 100%;
  font-size: 16px;
}

.content {
  background-color: white;
  padding: 24px;
  border-radius: 6px;
  max-width: 1400px;
}

.header {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 48px;
  color: #cb0121;
  display: flex;
  font-size: 20px;
  font-weight: bold;
}

.header div {
  width: 50%;
}

.header img {
  width: 50%;
  height: auto;
  margin-right: 16px;
  align-self: flex-start;
}

.instructions {
  margin-bottom: 18px;
}

button, input[type=submit] {
  -webkit-appearance: none;
  font-weight: bold;
  color: white;
  padding: 6px 8px;
  background-color: #439d49;
  font-size: 20px;
  border-radius: 6px;
  box-shadow: 2px 2px 8px #888888;
  border: none;
  outline: none;
  cursor: pointer;
}

.add-worker {
  margin-bottom: 24px;
}

.worker-item {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.worker-number, .worker-salary, .worker-type {
  margin-right: 8px;
}

.worker-number input {
  width: 32px;
}

.worker-type > div {
  border-radius: 3px;
  padding: 6px 8px;
  border: 1px solid black;
  font-size: 16px;
  min-width: 135px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.dropdown {
  position: absolute;
  background-color: white;
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: 0 4px 8px 2px #888888;
}

.dropdown li {
  padding: 6px 8px;
  min-width: 135px;
}

.dropdown li:hover {
  background-color: #439d49;
  cursor: pointer;
}

.worker-salary-item {
  display: flex;
  align-items: center;
}

.worker-salary input {
  width: 175px;
}

.remove-worker {
  background-color: #439d49;
  color: white;
  border-radius: 50%;
  text-align: center;
  height: 20px;
  width: 20px;
  line-height: 18px;
}

.remove-worker:hover {
  cursor: pointer;
}

.current-expenditure {
  margin-bottom: 32px;
}

.results div {
  font-weight: 500;
  margin-bottom: 16px;
}

.action-buttons {
  display: flex;
  margin-bottom: 8px;
}

.action-buttons input {
  margin-right: 8px;
}

.result-buttons {
  display: flex;
}

.result-buttons button {
  margin-top: 24px;
  margin-right: 8px;
}

.errors {
  min-height: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: red;
}

@media only screen and (max-width: 736px) {
  .App {
    padding: 12px 24px;
  }
  .worker-type, .worker-number, .worker-salary-item {
    margin-top: 8px;
  }
  .instructions, .intro, button, input[type=submit] {
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
  }
  .header {
    width: 200px;
    font-size: 14px;
  }
  .worker-type > div, .dropdown li  {
    min-width: 110px;
    font-size: 14px;
  }
  .worker-number input {
    width: 16px;
  }
  .worker-item {
    flex-wrap: wrap;
  }
  .worker-salary input {
    width: 150px;
  }
}
