.styled-input {
  position: relative;
  display: flex;
  align-items: center;
}

.styled-input input {
  border-radius: 3px;
  padding: 6px 8px;
  border: 1px solid black;
  font-size: 16px;
}

.with-type-character input {
  padding-left: 20px;
}

.type-character {
  position: absolute;
  left: 8px;
}

@media only screen and (max-width: 736px) {
  .styled-input input, .with-type-character {
    font-size: 14px;
  }
}
